import React, { useEffect } from 'react';
import { Spin, Button, Tooltip } from 'antd';
import moment from 'moment';
import FilterPopover from '../../components/FilterPopover';
import { SyncOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAllFunnels } from '../../../Funnel/redux/funnelSlice';
import { useDispatch, useSelector } from 'react-redux';
import { availableBookingStatuses, eventFilterState, eventsDownloading, isEventRefreshLoading, setEventFilters, setEventRefreshLoading, setEventsDownloading } from '../../../UI/redux/uiSlice';
import { AiOutlineCloudDownload } from 'react-icons/ai';
import { StateInterface } from '../../container/Events';
import { useEventsContext } from '../../context/EventsContext';

const defaultPage = {
  offset: 0,
  limit: 12
};

function EventFilter() {
  const location = useLocation() as any;
  const state = useSelector(eventFilterState);
  const isEventReloading = useSelector(isEventRefreshLoading);
  const _eventsDownloading = useSelector(eventsDownloading);
  const { availableStatus } = useEventsContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const funnels = useAllFunnels();

  useEffect(() => {
    if (location?.state) {
      const tempState: StateInterface = JSON.parse(JSON.stringify(state));

      if (location?.state?.start) tempState.bookingFilter.start = moment(location.state.start);
      if (location?.state?.end) tempState.bookingFilter.end = moment(location.state.end);
      if (location?.state?.funnelIds?.length > 0) tempState.bookingFilter.funnelIds = location?.state?.funnelIds

      dispatch(setEventFilters(tempState));
    }
  }, [location])

  function handleFunnelSelectionChange(value: number[]) {
    navigate('/kontakte', {
      state: {
        funnelIds: [...value]
      }
    });
    dispatch(setEventFilters({
      ...state,
      bookingFilter: {
        ...state.bookingFilter,
        funnelIds: value,
        pagination: {
          currentPageNum: 1,
          booking: {
            offset: defaultPage.offset,
            limit: defaultPage.limit
          },
          tracking: {
            offset: defaultPage.offset,
            limit: defaultPage.limit
          }
        }
      }
    }));
  }
  function handleOnSearchQueryChange(value: string) {
    dispatch(setEventFilters({
      ...state,
      bookingFilter: {
        ...state.bookingFilter,
        searchQuery: value,
        pagination: {
          currentPageNum: 1,
          booking: {
            offset: defaultPage.offset,
            limit: defaultPage.limit
          },
          tracking: {
            offset: defaultPage.offset,
            limit: defaultPage.limit
          }
        }
      }
    }));
  }

  const handleStatusChanged = (val: number) => {
    if (val == 0) {
      dispatch(setEventFilters({
        ...state,
        bookingFilter: {
          ...state.bookingFilter,
          statusId: undefined
        }
      }));
    }
    dispatch(setEventFilters({
      ...state,
      bookingFilter: {
        ...state.bookingFilter,
        statusId: val
      }
    }));
  };

  return (
    <div className='d-flex align-items-center'>
      <div className="csv-download__wrapper">
        <Tooltip title='Export'>
          <Button
            className="ant-btn__without-background ant-btn__disabled-default-style"
            style={{ margin: '0 4px 0 0' }}
            disabled={_eventsDownloading}
            loading={_eventsDownloading}
            onClick={_eventsDownloading ? () => {} : () => dispatch(setEventsDownloading(true))}
            icon={<AiOutlineCloudDownload size={28} className="agency-dashboard-brand-color" />}
          >
          </Button>
        </Tooltip>
      </div>

      <div className='d-flex'>
        <Tooltip title='Aktualisieren'>
          <Spin
            className='d-flex'
            indicator={
              <SyncOutlined
                className="agency-dashboard-brand-color"
                spin={isEventReloading}
                onClick={(e: any) => {
                  if (isEventReloading) return;
                  e.preventDefault();
                  e.stopPropagation();
                  dispatch(setEventRefreshLoading(true));
                }}
              />
            }
          />
        </Tooltip>
      </div>


      <FilterPopover
        defaultFunnelIds={state.bookingFilter.funnelIds}
        handleTypeChange={(value: any) =>
          dispatch(setEventFilters({
            ...state,
            bookingFilter: {
              ...state.bookingFilter,
              type: value,
              pagination: {
                currentPageNum: 1,
                booking: {
                  offset: defaultPage.offset,
                  limit: defaultPage.limit
                },
                tracking: {
                  offset: defaultPage.offset,
                  limit: defaultPage.limit
                }
              }
            }
          }))
        }
        type={state.bookingFilter.type}
        rangePickerProps={{
          defaultValue: [moment(state.bookingFilter.start), moment(state.bookingFilter.end)],
          onChange: (dates: any) => {
            dispatch(setEventFilters({
              ...state,
              bookingFilter: {
                ...state.bookingFilter,
                start: moment(dates[0]).startOf('day'),
                end: moment(dates[1]).endOf('day'),
                pagination: {
                  currentPageNum: 1,
                  booking: {
                    offset: defaultPage.offset,
                    limit: defaultPage.limit
                  },
                  tracking: {
                    offset: defaultPage.offset,
                    limit: defaultPage.limit
                  }
                }
              }
            }));
          }
        }}
        handleFunnelSelectionChange={handleFunnelSelectionChange}
        handleOnSearchQueryChange={handleOnSearchQueryChange}
        funnels={funnels}
        bookingStatus={state.bookingFilter.statusId ? state.bookingFilter.statusId : 0}
        availableStatus={availableStatus}
        handleChangeStatus={handleStatusChanged}
      />
    </div>
  );
}

export default EventFilter;
