import React, { useEffect, useState } from 'react';
import { Table, Tag, Space, Button, Dropdown, Menu, message } from 'antd';
import moment from 'moment';
import gql from 'graphql-tag';
import { TablePaginationConfig } from 'antd/es/table/interface';
import EventModal from './Event/EventModal';
import {
  BookingActionEventInterface,
  BookingInterface,
  BookingStatus,
  TrackingInterface
} from '../container/Events';
import {
  MoreOutlined,
  CloseOutlined,
  ContactsOutlined,
  DeleteOutlined,
  LoadingOutlined,
  LikeOutlined,
  DislikeOutlined
} from '@ant-design/icons';
import ActionModal from './Event/ActionModal';
import { useMutation } from '@apollo/react-hooks';
import { AgencyDashboarMessages } from '../../config/messages';
import DefaultSmallLoader from '../../SharedUI/components/DefaultSmallLoader';
import DefaultEmpty from '../../SharedUI/components/DefaultEmpty';
import dateFormats from '../../config/dateFormats';
import ActionEventIcon from './Event/ActionEventIcon';
import { getTextFromHtml } from '../../Builder/helper/sharedFunctions';
import { useEventsContext } from '../context/EventsContext';
function instanceOfTracking(object: any): object is TrackingInterface {
  return 'source' in object;
}

export interface ActionInterface {
  event: BookingInterface | TrackingInterface;
  action: 'APPROVED' | 'DECLINE';
  value: any;
  color?: string;
}
interface FlagStatus {
  APPROVED: BookingStatus;
  DECLINE: BookingStatus;
}
export const EventTable = ({
  loading,
  data,
  pagination,
  deleteBookingLoading,
  handleDelete,
  funnels
}: {
  loading: boolean;
  data: any;
  pagination: TablePaginationConfig;
  needlessEvent?: any;
  funnels: any[];
  deleteBookingLoading: boolean;
  handleDelete: (id: number, version: string, type: string, callBack?: Function) => Promise<any>;
  handleModal: (value: number | undefined) => void;
  expandedEvent: number | undefined;
}) => {
  const { setSelectedEvent, bookings, setBookings } = useEventsContext();

  const [toBeDeleted, setToBeDeleted] = useState<number | undefined>();

  useEffect(() => {
    setBookings(data);
  }, [data]);

  const funnelTitleById = funnels.reduce((acc, { title, id }) => {
    acc[id] = title;
    return acc;
  }, {});
  const handleLocalDelete = (eventId: number) => {
    const updatedBooking = bookings.filter(single => single.id !== eventId);
    setBookings(updatedBooking);
  };

  const columns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      width: '250px',
      render: (text: any, record: any) => {
        return (
          <div className="d-flex">
            <div className="table-column-icon">
              {record.bookingActionEvent && <ActionEventIcon record={record?.bookingActionEvent} />}
            </div>
            <div>
              <strong>{record.name || 'Unvollständig'}</strong>
              <div>{record.email || 'Der Funnel wurde verlassen'}</div>
            </div>
          </div>
        );
      }
    },
    {
      title: 'FUNNEL',
      dataIndex: 'funnelId',
      key: 'funnelId',
      width: '300px',
      render: (funnelId: string, record: any) => {
        const maxLength = 50;
        const title = funnelTitleById[funnelId];

        if (!title) return '';

        if (title.length > maxLength) return title.substring(0, maxLength) + '...';

        return title;
      }
    },
    {
      title: 'DATUM',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '150px',
      render: (text: any, record: any) => moment(text).format(dateFormats.dateReadableMonth)
    },
    {
      title: 'STATUS',
      dataIndex: 'createdAt',
      key: 'createdAt',
      maxWidth: '200px',
      render: (text: any, event: any) => {
        return (
          event?.bookingStatus?.status && (
            <div className="table-column-status">
              <Tag color={event?.bookingStatus?.color || 'default'}>
                {getTextFromHtml(event?.bookingStatus?.status)}
              </Tag>
              <div className="table-column-status__date">
                Geändert am {moment(text).format(dateFormats.dateReadableMonth)}
              </div>
            </div>
          )
        );
      }
    },
    {
      title: '',
      dataIndex: 'accept-reject',
      key: 'accept-reject',
      width: '50px',
      // align: 'right',
      render: (text: any, event: any, idx: number) => {
        return toBeDeleted === idx ? (
          deleteBookingLoading ? (
            <LoadingOutlined />
          ) : (
            <Space size="small">
              <Button
                danger
                type="link"
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();
                  handleDelete(
                    event?.id,
                    event.version,
                    instanceOfTracking(event) ? 'tracking' : 'booking',
                    handleLocalDelete
                  ).then(() => {
                    setToBeDeleted(undefined);
                  });
                }}
              >
                <DeleteOutlined />
                Löschen
              </Button>
              <Button type="link">
                <CloseOutlined
                  onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    setToBeDeleted(undefined);
                  }}
                />
              </Button>
            </Space>
          )
        ) : (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item
                  className="no-select"
                  key="4"
                  style={{ color: 'red' }}
                  onClick={e => {
                    e.domEvent.stopPropagation();
                    e.domEvent.preventDefault();
                    setToBeDeleted(idx);
                  }}
                >
                  <DeleteOutlined /> Löschen
                </Menu.Item>
              </Menu>
            }
          >
            <div className="funnel-overview__card__action no-select">
              <MoreOutlined
                style={{ fontSize: 20 }}
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
              />
            </div>
          </Dropdown>
        );
      }
    }
  ];
  return (
    <>
      <Table
        locale={{
          emptyText: (
            <DefaultEmpty
              icon={<ContactsOutlined />}
              title="Keine Bewerbungen vorhanden"
              description="Hier werden deine Bewerbungen aufgelistet, sobald sich jemand in deinen Funnel eingetragen hat."
            />
          )
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: () => {
              setSelectedEvent({ event: record, index: rowIndex });
            }
          };
        }}
        columns={columns}
        dataSource={bookings}
        loading={
          loading ? { indicator: <DefaultSmallLoader loading={true} fontSize={30} /> } : false
        }
        // @ts-ignore
        pagination={pagination?.total < pagination?.pageSize ? false : pagination}
        scroll={{ scrollToFirstRowOnChange: true }}
      />
    </>
  );
};
