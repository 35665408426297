import moment from 'moment';
import {
  DELETE_BOOKING,
} from '../container/Events';
import { GET_BOOKING_INFO } from '../context/EventsContext';

const eventsMockDate = {
  start: moment('2020-03-25T00:08:44.588Z'),
  end: moment('2020-03-25T00:08:44.588Z').add(3, 'weeks'),
  type: 'ALL',
  pagination: {
    currentPageNum: 1,
    booking: {
      offset: 0,
      limit: 12
    },
    tracking: {
      offset: 0,
      limit: 12
    }
  }
};

export const getBookingInfoMock = {
  request: {
    query: GET_BOOKING_INFO,
    variables: {
      filter1: {
        start: eventsMockDate.start.toISOString(),
        end: eventsMockDate.end.toISOString()
      },
      filter2: {
        start: eventsMockDate.start.toISOString(),
        end: eventsMockDate.end.toISOString()
      }
    }
  },
  result: {
    data: {
      getBookings: [
        {
          id: 1,
          name: 'Kurt Kunzer',
          email: 'kurt@kunzer.de',
          phone: '+124789054327',
          eventStart: '2020-04-12T00:58:50.755Z',
          eventEnd: '2020-04-12T00:48:50.755Z',
          createdAt: '2020-04-11T12:58:50.755Z',
          choices: [
            {
              choiceId: 1,
              leadQualifierId: 1,
              stringValue: 'Freunde',
              numberValue: null
            }
          ]
        },
        {
          id: 2,
          name: 'Sarah Wagner',
          email: 'sarah@wagner.de',
          phone: '+124439054327',
          eventStart: '2020-04-02T00:58:50.755Z',
          eventEnd: '2020-04-02T00:48:50.755Z',
          createdAt: '2020-04-01T12:58:50.755Z',
          choices: [
            {
              choiceId: 2,
              leadQualifierId: 2,
              stringValue: null,
              numberValue: 100
            }
          ]
        }
      ],
      getLeadQualifier: [
        {
          id: 1,
          question: 'Wie kann ich dir helfen?',
          choices: [
            {
              id: 1,
              title: 'Freunde',
              unit: null
            }
          ]
        },
        {
          id: 2,
          question: 'Was willst du verdienen?',
          choices: [
            {
              id: 2,
              title: 'Kohle',
              unit: 'percent'
            }
          ]
        }
      ],
      getTrackings: [
        {
          id: 1,
          createdAt: '2020-04-01T00:58:40.755Z',
          totalDurationInSeconds: 30,
          source: 'https://www.facebook.de/hans.peter1',
          completed: false,
          progressCollectors: [
            {
              key: 'pre-page_1',
              leadQualifierId: null,
              firstActivity: '2020-04-01T00:58:40.755Z',
              durationInSeconds: 5,
              choices: []
            },
            {
              key: 'leadQualifier_1',
              leadQualifierId: 1,
              firstActivity: '2020-04-01T00:58:50.755Z',
              durationInSeconds: 10,
              choices: [
                {
                  choiceId: 1,
                  stringValue: null,
                  numberValue: null
                }
              ]
            },
            {
              key: 'leadQualifier_2',
              leadQualifierId: 2,
              firstActivity: '2020-04-01T00:59:00.755Z',
              durationInSeconds: 10,
              choices: [
                {
                  choiceId: 2,
                  stringValue: null,
                  numberValue: null
                }
              ]
            },
            {
              key: 'submit-form',
              leadQualifierId: null,
              firstActivity: '2020-04-01T00:59:10.755Z',
              durationInSeconds: 5,
              choices: []
            }
          ]
        },
        {
          id: 2,
          createdAt: '2020-04-02T00:58:40.755Z',
          totalDurationInSeconds: 5,
          source: 'https://www.pumpen-shop.info/id=3',
          completed: false,
          progressCollectors: [
            {
              key: 'pre-page_1',
              leadQualifierId: null,
              firstActivity: '2020-04-02T00:58:40.755Z',
              durationInSeconds: 5,
              choices: []
            }
          ]
        }
      ]
    }
  }
};

export const deleteBookingMockSuccess = {
  request: {
    query: DELETE_BOOKING,
    variables: {
      bookingId: 1
    }
  },
  result: {
    data: {
      deleteBooking: true
    }
  }
};

export const deleteBookingMockError = {
  request: {
    query: DELETE_BOOKING,
    variables: {
      bookingId: 2
    }
  },
  result: {
    data: {
      deleteBooking: false
    }
  },
  error: new Error()
};

export default [getBookingInfoMock];
