import React, { useEffect } from 'react';
import { Drawer, Skeleton } from 'antd';
import EventDetailContent, { PropsInterface } from './EventDetailContent';

function EventModal({
  selectedEvent,
  setSelectedEvent,
  eventLoading,
  ...rest
}: PropsInterface) {
  useEffect(() => {
    const helpButton = document.getElementById('help-button-container') as any;
    const isVisible = !!selectedEvent || eventLoading;
    if (helpButton?.style?.display) {
      helpButton.style.display = isVisible ? 'none' : 'block';
    }
  }, [!!selectedEvent, eventLoading]);

  return (
    <div className="events__event" data-testid="event-item-drawer">
      <Drawer
        className="event-summary-modal"
        title="Zusammenfassung"
        visible={!!selectedEvent || eventLoading}
        onClose={() => setSelectedEvent(undefined)}
      >
        {eventLoading ? (
          <Skeleton active />
        ) : (
          selectedEvent && (
            <EventDetailContent
              {...rest}
              selectedEvent={selectedEvent}
              setSelectedEvent={setSelectedEvent}
            />
          )
        )}
      </Drawer>
    </div>
  );
}

export default EventModal;
